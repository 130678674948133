import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { LabeledValue } from 'antd/lib/tree-select'
import React from 'react'
import { useFetchExternalAuthConnections } from '../../../api'

const { Option } = Select

export const ExternalAuthConnectionSelector: React.FC<SelectProps<LabeledValue | LabeledValue[]>> = props => {
  const { data, loading } = useFetchExternalAuthConnections()
  const authConnections = (data && data.externalAuthConnections) || []
  return (
    <Select {...props} loading={loading}>
      {authConnections.map(authConnection => (
        <Option title={authConnection.name} key={authConnection.id} value={authConnection.id}>
          {authConnection.name}
        </Option>
      ))}
    </Select>
  )
}
